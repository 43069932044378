<template>
  <div class="badge" :class="color">
    {{ status | sentenceCase }}
  </div>
</template>

<script>
export default {
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      switch (this.status) {
        case "approved":
          return 'badge-green-soft-outline';
        case "disbursed":
          return 'badge-green-soft-outline';
        case "declined":
          return 'badge-red-soft-outline';
        case "paid":
          return 'badge-green-soft-outline';
        case "pending":
        default:
          return 'badge-orange-soft-outline';
      }
    },
    status() {
      return this.loan?.status;
    },
  },
};
</script>
